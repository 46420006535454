<template>
    <div>
      <Footer></Footer>
      <div class="content" >

<p class="contenTitle">“大唐杯”全国双师型教师新一代信息通信技术大赛报名</p>
<p style="text-align: center;">
</p>
<p style="text-align: center;">
    <span class="contentSpan">您的姓名：</span><input type="text" class="contentInput" >
        &emsp;&emsp;
    <span class="contentSpan">您的手机号：</span><input type="text" class="contentInput">
</p>

<p style="text-align: center;">
    <span class="contentSpan">您的身份证号：</span><input type="text" class="contentInput"  >
    &emsp;&emsp;
    <span class="contentSpan">您的邮箱：</span><input type="text" class="contentInput"  >
</p>

<p style="text-align: center;">
    <span class="contentSpan">您所在的学校：</span> <el-input class="contentInput"  show-password></el-input>
    &emsp;&emsp;
    <span class="contentSpan">参赛组别：</span><input type="text" class="contentInput" >
</p>
<p style="text-align: center;">
    <span class="contentSpan">您所在的省份：</span><el-input class="contentInput"  show-password></el-input>
    &emsp;&emsp;
    <span class="contentSpan">您的职务：</span><input type="text" class="contentInput">
</p>

<p style="text-align: center;">
    <span class="contentSpan">您所在的学院：</span><el-input class="contentInput"  show-password></el-input>
    &emsp;&emsp;
    <span class="contentSpan"></span><input type="text" class="contentInput">
</p>



<p style="text-align: center;">
    <el-button type="info" id="contentBut" @click="step">提交报名信息</el-button>
</p>
<div class="contentText">
    <span >大赛注册及报名信息收集仅用于大赛组委会审核与竞赛，我们将高度重视您的个人信息安全，并采取符合法律法规的安全措施，保护您的个人信息，防止发生个人信息的泄露、丢失、等安全风险。</span>
</div>
</div>
    <Bottom></Bottom>
    </div>
  </template>
  
  <script>
  import Footer from '@/components/Footer/Footer.vue'
  import Bottom from '@/components/Bottom/Bottom.vue'
  import $ from "jquery";
  export default {
    name: 'Home',
    components: {
      Footer,
      Bottom
    },
    data(){
      return {
  
      }
    },
    methods:{

    },
    mounted(){
  
    },
    created(){
  
    },
  }
  </script>
  
  <style scoped lang="scss">
  :deep .el-upload__tip{
    font-size: .14rem;
    margin: auto .1rem;
    
}
:deep .el-upload {
    margin-left: 2.6rem;
}
:deep .el-upload >button {
    font-size: .14rem;
    padding: .12rem;
}
:deep .el-upload-list--picture .el-upload-list__item{
    width: 2rem;
    margin: auto
}
input[readonly]{
    color: #929292
}
:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
  cursor: default;
  .el-input__inner {
    cursor: default !important;
  }
}
:deep .el-upload-list__item-info{
    margin-left: 3.2rem;
    display:inline-block;
}
/deep/ .el-dialog__header {
  background: #d7edfb !important;
  margin-right: 0 !important;
}


.content{
    width: 12.1rem;
    margin: 0 auto;
    overflow:hidden;
    // height: 7rem;
    background-color: #FFFFFF;
    p{
        margin-top: .1rem;
    }
    .contenTitle{
        text-align: center;
        color: #436E8A;
        font-size: .28rem;
        margin-top: .4rem;
    }
    .cross{
        width: 6rem;
        height: .14rem;
        opacity: 1;
        margin:.2rem auto;
    }
    .contentSpan{
        font-size: .18rem;
        width: 1.3rem;
        display:inline-block;
        text-align: right;
    }
    .contentInput{
        width: 3rem;
        height: .4rem;
        opacity: 1;
        margin:.1rem auto;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: .02rem #A8A5A5 solid;
        font-size: .18rem;
        font-weight: 500;
        padding: .1rem;
    }
    .contentInput:focus{
        outline: 0;
        border-top: none;
        border-left: none;
        border-right: none;
        padding: .1rem;
    }
    .contentInput::placeholder{
        color: #7E7E7E;
        text-align: left;
        padding: .1rem;
    }
    #contentBut{
        margin: .2rem auto .4rem;
        width: 3rem;
        height: .5rem;
        border-radius: .1rem;
        opacity: 1;
        background-color: #436E8A;
        font-size: .18rem;
        color: #fff;
    }
    .contentText{
        margin: 0 auto .6rem;
        width: 6rem;
        font-size: .134rem;
        line-height: .2rem;
    }
    .contentBox{
        position: absolute;
        left: 9.7rem;
    }
    .contentBox>p{
        margin-top: .2rem;
    }
}
.contentDiv{
    display: flex;
    width: 7rem;
    margin: .2rem auto;
}
.contentDiv>div{
    width: 1.6rem;
    display: inline-block;
}
.contentDiv>div>img{
    width: 100%;
}
.uploadSpan{
    width: 1.6rem;
    display: inline-block;
    margin: auto .2rem;
    font-size: .14rem;
}



.Title{
    width: 6rem;
    color: #000;
    font-weight: 600;
    margin: auto;
    font-size: .15rem;
}
.contBox{
    position: absolute;
    left: 9.7rem;
}
.Boximg{
    margin-left: 2rem;
    margin-top: .2rem;

}
.Boximg>div{
    width: 4rem;
    height: 3rem;
    display: inline-block;
}

.contBoxT{
    position: absolute;
    left: 9.7rem;
    top: 5rem;
}

  
  </style>
  